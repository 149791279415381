<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!-- Cell Column -->
    <ng-container matColumnDef="cellKey">
      <th mat-header-cell *matHeaderCellDef> Cell  </th>
      <td mat-cell *matCellDef="let bet"> {{bet.cellKey}} </td>
    </ng-container>
  
    <!-- Amount Column -->
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef> Amount </th>
      <td mat-cell *matCellDef="let bet"> {{bet.amount}} </td>
    </ng-container>

  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  
  