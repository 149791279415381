<mat-grid-list cols="5" rowHeight="45px" gutterSize=10>
    <mat-grid-tile *ngFor="let cell of cells.slice(0,45)" [colspan]="cell.colspan" [rowspan]="cell.rowspan"
    [style.background]="mapFillColor(cell.color)" (click)=placeBet(cell) class="clickable-tile">
    <div class="cell-label">{{cell.key}}</div>
</mat-grid-tile>
<mat-grid-tile [colspan]=2 [rowspan]=1 class="invisible-tile">
</mat-grid-tile>
<mat-grid-tile *ngFor="let cell of cells.slice(45,48)" [colspan]="cell.colspan" [rowspan]="cell.rowspan"
[style.background]="mapFillColor(cell.color)" (click)=placeBet(cell) class="clickable-tile">
<div class="cell-label">{{cell.key}}</div>
</mat-grid-tile>
</mat-grid-list>
<br/><br/>
    <div style='width:30%;' *ngIf="bets.length>0">
        <app-bet-table [bets]="bets"></app-bet-table>
        <br/>
        <button mat-raised-button (click)=clearBets()>Clear bets</button>
    </div>
    <br/><br/>
<div>
    <br>
    <button mat-raised-button (click)=spin()>Spin</button>
    <br><br>
    Account: {{userAddress}} 
    <br><br>
    DeCa Balance: {{tokenBalance}} 
    <br><br>
    Total DeCa supply: {{tokenTotalSupply}} 
    <br><br>
    <div *ngIf="loading"><mat-spinner></mat-spinner></div>

    <br><br>
</div>
